<template>
  <div class="app">
    <comhd @search="dosearch"></comhd>
<!--    <md-drop-menu :data="dpmenu"  :default-value="curMenu" @change="doChange"/>-->

    <div class="main">
        <md-scroll-view
            :scrolling-x="false"
            auto-reflow
            @end-reached="handleToEnd"
            @refreshing="handleToTop"
            ref="sc"
        >
          <md-scroll-view-refresh
              slot="refresh"
              slot-scope="{ scrollTop, isRefreshActive, isRefreshing }"
              :scroll-top="scrollTop"
              :is-refreshing="isRefreshing"
              :is-refresh-active="isRefreshActive"
          ></md-scroll-view-refresh>

<!--          <div class="tuhd">
            <div>党建园地</div>
            <div class="more">更多
              <md-icon name="arrow-right" size="mini"/>
            </div>
          </div>-->


          <md-tabs v-show="showjj">
            <md-tab-pane class="content" name="p1" label="本馆简介">
              <div class="jj" v-html="jj.intro"></div>
            </md-tab-pane>
            <md-tab-pane class="content" name="p2" label="领导班子">
              <div class="jj" v-html="jj.leaders"></div>
            </md-tab-pane>
            <md-tab-pane class="content" name="p3" label="部门设置" >
              <div class="jj" v-html="jj.depart"></div>
            </md-tab-pane>
          </md-tabs>

          <div class="tuwen" :class="{tuwen2: istuwen2}">
            <!--   图文1模式-->
           <template  v-if="istuwen2">
             <div class="t2item" v-for="item of list" :key="item.aid" @click="$router.push({name:'detail',query:{id:item.aid}})">
               <div class="title">{{ item.title }}</div>
               <div class="cover">
                 <img :src="item.cover" alt="">
               </div>
               <div class="info">
                 <div class="desc">{{ item.desc }}</div>
                 <div class="detail">
                   <div class="author">{{ item.author }}</div>
                   <div class="time">{{item.addTime|format('YYYY-MM-DD')}}</div>
                 </div>
               </div>
             </div>

           </template>
            <!--   图文2模式-->
            <template v-else>
              <div class="twitem" v-for="item of list" :key="item.aid" @click="$router.push({name:'detail',query:{id:item.aid}})">
                <div class="cover">
                  <img :src="item.cover" alt="">

                </div>
                <div class="info">
                  <div class="title">{{ item.title }}</div>
                  <div class="desc">{{ item.desc }}</div>
                  <div class="detail">
                    <div class="author">{{ item.author }}</div>
                    <div class="time">{{item.addTime|format('YYYY-MM-DD')}}</div>
                  </div>
                </div>
              </div>

            </template>

          </div>
          <md-scroll-view-more
              slot="more"
              :is-finished="isFinished"
          >
          </md-scroll-view-more>
        </md-scroll-view>


      </div>
    <navSlide />
  </div>



</template>

<script>
export default {
name: "List",
  data(){
    return {
      dpmenu:[
        {text:'栏目',options: []},
        {text:'分类',options: []},
        { text:'排序',options:[
            {value: 1, text: '全部'},
            {value: 2, text: '浏览量'},
            {value: 3, text: '最新发布'},
          ]
        }
      ],
      items: [
        {value: 1, text: '全部'},
        {value: 2, text: '浏览量'},
        {value: 3, text: '最新发布'},
      ],
      subCates:[

      ],
      cInfo:null,
      curCate:null,
      orderBy: 1,
      typeBy:'',
      list:[],
      page:1,
      count:0,
      isFinished:false,
      keyword:'',
      istuwen2:false,
      fenlei:[],
      curMenu:[1],
      showjj:false,
      jj:{
        intro:'',
        leaders:'',
        depart:''
      }
    }
  },

  created(){
      if(this.$route.query.big == 1) this.istuwen2 = true
  },
  watch:{
  showjj(nv){
    console.log('nvnvnv',nv)
  },
    curCate(nv){
       if(nv) this.getCateInfo()
    },
    $route(to, from) {
      console.log('路由有变化')
      this.handleToTop();
      this.getCateInfo();

      if(to.name==='List') this.getSubCates()

    }

  },
  beforeRouteEnter(to,from,next){

       window.sessionStorage.setItem('from',from.name);
       next();
  },
  beforeRouteLeave(to,from,next){

    // this.orderBy = 1
    this.dpmenu=[
      {text:'栏目',options: []},
      {text:'分类',options: []},
      { text:'排序',options:[
          {value: 1, text: '全部'},
          {value: 2, text: '浏览量'},
          {value: 3, text: '最新发布'},
        ]
      }
    ],
    next()
  },
  deactivated() {
    this.dpmenu = [
      {text:'栏目',options: []},
      {text:'分类',options: []},
      { text:'排序',options:[
          {value: 1, text: '全部'},
          {value: 2, text: '浏览量'},
          {value: 3, text: '最新发布'},
        ]
      }
    ]
    this.curCate = null

  },
  activated() {
    this.showjj  = false
    const from = window.sessionStorage.getItem('from');
    if(from==='detail') return false
    if(this.$route.query.big == 1) this.istuwen2 = true
    this.list = []
    this.isFinished = false
    this.page = 1
    this.subCates = []
    this.fenlei = []
    this.dpmenu = [
      {text:'栏目',options: []},
      {text:'分类',options: []},
      { text:'排序',options:[
          {value: 1, text: '全部'},
          {value: 2, text: '浏览量'},
          {value: 3, text: '最新发布'},
        ]
      }
    ]
    this.getData()
    this.getSubCates()



  },
  mounted(){
      this.getData()
      this.getSubCates()


    // 修改 md-drop-menu-list的pt

     let domtop = document.querySelector('.md-drop-menu-list')
     let _style = window.getComputedStyle(domtop)
     const { paddingTop } = _style
     // domtop.style.marginTop = parseInt(paddingTop)*1 + 'px'
     document.querySelector('.md-popup').style.top  =     paddingTop


  },
  methods:{
    async getCateInfo(){
       const cid = this.curCate ?  this.curCate : this.$route.query.cid
      if(!cid) return false
      const res = await this.$api.post('/mapi/getCateInfo',{cid})
      const { success,data } = res.data;
      if(success){
        this.cInfo = data;


        try{

          document.querySelector(".bar-item:first-of-type span").innerText = this.cInfo.name
          if(cid==66){
            document.querySelector(".bar-item:first-of-type span").innerText = '本馆概况';

            this.jj = this.cInfo
            this.showjj  = true
          }else{
            this.showjj  = false
          }
        }catch (e) {
          console.log(e)
        }

        this.fenlei = []

        const { tags } = data;
        if(tags){
          this.dpmenu = this.dpmenu.filter(r=>r.text !=='分类')
          this.dpmenu.splice(1,0,{text:'分类',options:[{value:'',text:'全部分类'},...data.tags.split(",").map(r=>({
              value: r,
              text: r
            }))] })

        }else{
          this.dpmenu.splice(1,1)
        }
      }
    },
     async dosearch(res){
        this.keyword = res
        this.isFinished = false;
        this.page = 1;
        this.list = []
        this.getData()
     },
     async getSubCates(){
       let res = await this.$api.post('/mapi/getSubCates',{
         cid:this.$route.query.cid
       })

       // 排除的栏目
       const exlude = ['在线留言']
       const { success,data } = res.data
       if(success&&data.length){
         this.dpmenu[0] = { text:'栏目',options:data.map(r=>({value:r.cid,text:r.name})).filter(r=>!exlude.includes(r.text)) }
          this.curMenu = [0]
       }else{

       }
       await this.getCateInfo()



     },
    async doChange(barItem, listItem){
      const type = barItem.text;
      console.log(listItem)
      console.log(listItem.value,type)

      if(type === '排序')  this.orderBy = listItem.value
      if(type === '分类')  this.typeBy = listItem.value
      if(type === '栏目')  this.curCate = listItem.value

      this.page = 1
      this.list = []
      this.isFinished = false
      this.getData();


    },
     async handleTabChange(){
       this.list = []
       this.page = 1;
       this.getData();
       this.isFinished = false
     },
     async getData(){
          if(this.isFinished)  return false;
          let { cid } = this.$route.query
          let order = '';
          if(this.curCate) cid = this.curCate
          if(this.orderBy === 1) order=''
          if(this.orderBy === 2) order='byview'
          if(this.orderBy === 3) order='bytime'
          let res = await this.$api.post('/mapi/acticleList',{
                cid,
                order,
                page:this.page,
                limit:10,
                keyword:this.keyword,
                type:this.typeBy
          })
          const  { count,rows,success}  = res.data;
          if(success){
            this.count = count
            const already = this.list.map(r=>r.aid);
            this.list = this.list.concat(rows.filter(r=>!already.includes(r.aid) ))
            this.$refs.sc.finishLoadMore()
            if(this.list.length >= this.count) this.isFinished = true
          }

     },
     async handleToEnd(){
        if(!this.isFinished)  this.page++
       this.getData()

     },
    async handleToTop(){
       this.list = []
       this.page = 1;
       this.isFinished = false
      await this.getData()
      this.$refs.sc.finishRefresh()
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  height: 0;
  flex-grow: 1;
}
::v-deep {
  .md-tab-bar-item{
    min-height: 60px;
  }
  .sub{
    border-bottom: 1px solid #d4d4d4;
  }
  .sub .md-tab-bar-item{
    min-height: 100px;
  }
  .md-drop-menu{
    position: static;
  }

}
.jj{
  padding:30px;

}
.jj img{
  max-width: 80%!important;
  margin:0 auto!important;
}
</style>
